import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import ServiceCard from "../components/serviceCard"
import CardDeck from "react-bootstrap/CardDeck"
import { Container, Row, Col } from "react-bootstrap"
import imgExteriorDetail from "../images/cleaning-1837332_640.jpg";
import imgWipe1 from "../images/wipe-3625683_640.jpg";
import imgWipe2 from "../images/wiper-2870283_640.jpg";
import raptorImage from "../images/raptor.png"
import Button from "react-bootstrap/Button";
import { graphql, useStaticQuery } from 'gatsby';

// https://github.com/iandevlin/html5bones/blob/master/main-template.html

export default () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "car-wash-3960877_1920.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const heroImage = data.desktop.childImageSharp.fluid;

  return(
    <Layout>
      <Hero
        Title={"Luxury Detail"}
        Subtitle={"Rugged Cross Auto Detail"}
        Text={"I find pride in providing a level of service and professionalism rarely found in today’s society. I believe in being up-front, honest, and delivering my customers high quality services that surpass their expectations."}
        Img={heroImage}
        BgColor={`#040e18`}
      />
      <section className={"services"}>
        <h2 className={"extra-space"}>Services</h2>
        <Container>
          <Row>
            <Col>
              <CardDeck>
                <ServiceCard ImgSrc={imgExteriorDetail} href="/services/full-detail" Title={"Full Detail"} Text="This service includes all the bells and whistles starting with a thorough hand-washing and drying of your vehicle. The exterior receives a single step paint correction and is finished off with wax (up to 4 months of protection). If you would like long-lasting paint sealer instead of wax, we offer discounts when the paint sealer is added to this service."/>
                <ServiceCard ImgSrc={imgWipe2} Title={"Exterior Detail"} href="/services/exterior-detail" Text="Made for the vehicle that needs a little extra care on its exterior. This service involves a thorough hand wash, cleaning of the wheels, and tire dressing. The exterior receives a single step paint correction and is finished off with wax (up to 4 months of protection). If you would like long-lasting paint sealer instead of wax, we offer discounts when the paint sealer is added to this service."/>
                <ServiceCard ImgSrc={imgWipe1} Title={"Ceramic Coating"} href="/services/3-year-ceramic-coating" Text="The 3 year ceramic paint coating blankets your vehicle in a glossy shell of ceramic protection that complements all colors. Black will appear deep; red will dazzle with bright reflections; silver and white will look like molten glass."/>
                <ServiceCard ImgSrc={raptorImage} Title={"RAPTOR Protective Coating"} href="/services/raptor-protective-coating" Text="RAPTOR lining services are offered for truck bed liners, as an overall vehicle surface protectant, and for other surfaces. Multiple colors and color-matching are available."/>
              </CardDeck>
              <div className={"text-center extra-space"}>
                <Button href="/services" variant="outline-secondary" size="lg">
                  All Services
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
